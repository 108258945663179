<template>
    <div>
        <div style="display:inline-block;width:100%;height:100%;">
            <div class="child-top" style="padding-top:0;">
                <div class="input-from">
                    <el-select @change="getClassify" class="last-child" clearable placeholder="请选择指标所属分类"
                               style="margin-right:10px;width: 150px;" v-model="selectArr.grade">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in options">
                        </el-option>
                    </el-select>
                    <div style="float: left;">
                        <el-input
                                @keyup.enter.native="getData(true)"
                                class="left-child-input left-child-input-content"
                                clearable
                                placeholder="请输入名称"
                                style="width:200px;float:left;"
                                v-model="input4"
                        >
                            <el-button @click="getData(true)" icon="iconfont iconsousuo" slot="append"></el-button>
                        </el-input>
                    </div>
                </div>
                <div class="operation-button" style="margin-top:10px;">
                    <el-button
                            @click="addDataLog"
                            :disabled="$store.getters.permissionsStr('新增')"
                            class="brotherBtns" style="border-right:0;">
                        <i class="iconfont icon-xinjian-icon" style="color:#4BB3FF;"></i>
                        <span>新增</span>
                    </el-button>
                    <!--                    :class="$store.getters.permissionsStr('删除') ? 'disabledRed' : 'noDisabledRed'"-->
                    <!--                    :disabled="tableDataSelectArr.length == 0 || $store.getters.permissionsStr('删')"-->
                    <el-button @click="$down('/p/norm/downloadTemplate')"
                               :disabled="$store.getters.permissionsStr('下载')"
                               class="brotherBtns" style='width:90px;'>
                        <i class="iconfont icon-xiazai-icon" style="color:#4BB3FF;"></i>
                        <span>下载模板</span>
                    </el-button>
                    <el-button class="brotherBtns importFile room"
                               :disabled="$store.getters.permissionsStr('导入')"
                               style="border-right:0;">
                        <div class="fileInp">
                            <input @input="$importFile($event,'indexSetting')" type="file">
                        </div>
                        <i class="iconfont icon-daoru-icon" style="color:#F5A623;"></i>
                        <span>导入</span>
                    </el-button>
                    <el-button @click="expor" :disabled="$store.getters.permissionsStr('导出')">
                        <i class="iconfont icon-daochu-icon" style="color:#FF6666;"></i>
                        <span>导出</span>
                    </el-button>
                </div>
            </div>
            <!-- 指标设置表格-->
            <div class="content-flex-pages">
                <el-table
                        :data="tableData"
                        @selection-change="tableSelectionChange"
                        class="content-test-table"
                        style="width: 100%"
                        v-loading="tableLoading"
                >
                    <el-table-column type="selection" width="45"></el-table-column>
                    <el-table-column
                            label="序号"
                            type="index"
                            width="45">
                        <template slot-scope="scope">
                            {{(currentPage-1)*10+scope.$index+1}}
                        </template>
                    </el-table-column>
                    <el-table-column label="编号" prop="code" width="90"></el-table-column>
                    <el-table-column label="指标名称" prop="cn_name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="指标单位" prop="unit_name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="指标说明" prop="norm_explain" show-overflow-tooltip></el-table-column>
                    <el-table-column label="顺序" prop="order_no" show-overflow-tooltip></el-table-column>
                    <el-table-column label="状态" prop="status" show-overflow-tooltip width="80">
                        <template slot-scope="scope">
                            <el-switch
                                    active-color="#0055E9"
                                    :active-value="1"
                                    :inactive-value="0"
                                    :width="35"
                                    @change="ChangeState(scope.row)"
                                    :disabled="$store.getters.permissionsStr('启/停用') || scope.row.outer_name != '' ? true : false"
                                    inactive-color="#ccc"
                                    v-model="scope.row.status"
                            ></el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button
                                    v-show="!$store.getters.permissionsStr('查看')"
                                    @click="examineData(scope.row)"
                                    size="medium"
                                    style="color: #F5A623;margin-right: 20px;"
                                    type="text"
                            >查看
                            </el-button>
                            <el-button
                                    @click="editorData(scope.row,scope.$index)"
                                    v-show="!$store.getters.permissionsStr('编辑')"
                                    size="medium"
                                    :style="{'color': scope.row.can_op ? '#0055E9' : '#ccc', 'margin-right':'20px'}"
                                    type="text" 
                                    :disabled="!scope.row.can_op || scope.row.outer_name != '' ? true : false"
                            >编辑
                            </el-button>
                            <el-button
                                    v-show="!$store.getters.permissionsStr('删除')"
                                    @click="delTableData(scope.row)"
                                    size="medium"
                                    :style="{'color': scope.row.can_op ? '#FD3258' : '#ccc'}"
                                    type="text"
                                    :disabled="!scope.row.can_op || scope.row.outer_name != '' ? true : false"
                            >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        :current-page.sync="currentPage"
                        :page-size="10"
                        :total="tableTotal"
                        @current-change="handleCurrentChange"
                        class="table-pagination"
                        layout="total, prev, pager, next , jumper"
                ></el-pagination>
            </div>
            <!--            新增彈窗-->
            <el-dialog :close-on-click-modal="false" :visible.sync="dialogTableVisible" :before-close="remove"
                       title="" width='40%'>
                <div class="ModifyDoalog">
                    <p>{{!dialogTableVisibleType ? '新增' : '编辑'}}指标</p>
                    <el-form :label-position="'left'" :model="form" :rules="rules" label-width="100px" ref="form">
                        <el-form-item label="编号" prop="code">
                            <el-input disabled v-model="form.code"></el-input>
                        </el-form-item>
                        <el-form-item label="指标名称" prop="cn_name">
                            <el-input v-model="form.cn_name"></el-input>
                        </el-form-item>
                        <el-form-item label="单位" prop="unit_uuid">
                            <el-select class="last-child" clearable placeholder="选择单位"
                                       v-model="form.unit_uuid">
                                <el-option
                                        :key="item.index"
                                        :label="item.label"
                                        :value="item.value"
                                        v-for="item in unitOptions">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="指标说明" prop="norm_explain">
                            <el-input v-model="form.norm_explain"></el-input>
                        </el-form-item>
                        <el-form-item label="顺序" prop="order_no">
                            <el-input v-model="form.order_no"></el-input>
                        </el-form-item>
                        <el-form-item label="指标值类型">
                            <el-radio :label="1" v-model="form.value_type">数字</el-radio>
                            <el-radio :label="2" v-model="form.value_type">文本</el-radio>
                        </el-form-item>

                        <el-form-item label=""  v-if="form.value_type === 1" style="margin-bottom: 0px;">
                            <el-tabs v-model="activeName">
                                <el-tab-pane label="范围设置" name="first"></el-tab-pane>
                                <el-tab-pane label="评分设置" name="second"></el-tab-pane>
                            </el-tabs>
                        </el-form-item>

                        <el-form-item label="标准范围（男）" v-if="activeName == 'first' & form.value_type === 1">
                            <el-select @change="standardMan" clearable placeholder="选择关系" v-model="form.male_symbol">
                                <el-option :value="1" label="<"></el-option>
                                <el-option :value="2" label="<="></el-option>
                                <el-option :value="3" label=">"></el-option>
                                <el-option :value="4" label=">="></el-option>
                                <el-option :value="5" label="区间（3段）左开右开"></el-option>
                                <el-option :value="6" label="区间（3段）左开右闭"></el-option>
                                <el-option :value="7" label="区间（3段）左闭右开"></el-option>
                                <el-option :value="8" label="区间（3段）左闭右闭"></el-option>
                            </el-select>
                        </el-form-item>
                        <!--                        大于是male_lower_limit 小于是male_upper_limit-->
                        <el-form-item label="小于（<）" v-show="form.male_symbol === 1 & form.value_type === 1  & activeName == 'first'">
                            <el-input clearable v-model="form.male_upper_limit"></el-input>
                        </el-form-item>
                        <el-form-item label="小于等于（<=）" v-show="form.male_symbol === 2 & form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.male_upper_limit"></el-input>
                        </el-form-item>
                        <el-form-item label="大于（>）" v-show="form.male_symbol === 3 & form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.male_lower_limit"></el-input>
                        </el-form-item>
                        <el-form-item label="大于等于（>=）" v-show="form.male_symbol === 4 & form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.male_lower_limit"></el-input>
                        </el-form-item>
                        <el-form-item
                                v-show="(form.male_symbol === 5 & form.value_type === 1 & activeName == 'first')|| (form.male_symbol === 6 & form.value_type === 1 & activeName == 'first')|| (form.male_symbol === 7& form.value_type === 1 & activeName == 'first')|| (form.male_symbol === 8& form.value_type === 1 & activeName == 'first')">
                            <el-input clearable style="width: 45%;" v-model="form.male_lower_limit"></el-input>
                            <span style="margin-left: 3%;">至</span>
                            <el-input clearable style="width: 45%;float: right;" v-model="form.male_upper_limit"></el-input>
                        </el-form-item>

                        <el-form-item label="标准范围（女)" v-if="activeName == 'first'  & form.value_type === 1">
                            <el-select @change="standardWoman" clearable placeholder="选择关系"
                                       v-model="form.female_symbol">
                                <el-option :value="1" label="<"></el-option>
                                <el-option :value="2" label="<="></el-option>
                                <el-option :value="3" label=">"></el-option>
                                <el-option :value="4" label=">="></el-option>
                                <el-option :value="5" label="区间（3段）左开右开"></el-option>
                                <el-option :value="6" label="区间（3段）左开右闭"></el-option>
                                <el-option :value="7" label="区间（3段）左闭右开"></el-option>
                                <el-option :value="8" label="区间（3段）左闭右闭"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="小于（<）" v-show="form.female_symbol === 1& form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.female_upper_limit"></el-input>
                        </el-form-item>
                        <el-form-item label="小于等于（<=）" v-show="form.female_symbol === 2& form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.female_upper_limit"></el-input>
                        </el-form-item>
                        <el-form-item label="大于（>）" v-show="form.female_symbol === 3& form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.female_lower_limit"></el-input>
                        </el-form-item>
                        <el-form-item label="大于等于（>=）" v-show="form.female_symbol === 4& form.value_type === 1 & activeName == 'first'">
                            <el-input clearable v-model="form.female_lower_limit"></el-input>
                        </el-form-item>
                        <el-form-item
                                v-show="(form.female_symbol === 5& form.value_type === 1  & activeName == 'first') || (form.female_symbol === 6& form.value_type === 1  & activeName == 'first')|| (form.female_symbol === 7& form.value_type === 1  & activeName == 'first')|| (form.female_symbol === 8& form.value_type === 1  & activeName == 'first')">
                            <el-input clearable style="width: 45%;" v-model="form.female_lower_limit"></el-input>
                            <span style="margin-left: 3%;">至</span>
                            <el-input clearable style="width: 45%;float: right;" v-model="form.female_upper_limit"></el-input>
                        </el-form-item>

                        <el-form-item v-if="activeName == 'second'">
                            <div>
                                整数 
                                <el-select placeholder="选择整数" v-model="form.accuracy_num"  style="width: 80%; margin-left: 10px;">
                                    <el-option
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                            v-for="item in accuracyOptions">
                                    </el-option>
                                </el-select>
                            </div>
                            
                            <p>分值设置（男）</p>
                            <el-table
                                :data="manTableData"
                                border
                                style="width: 100%">
                                <el-table-column
                                    type="index"
                                    width="50"
                                    label="区间编号">
                                </el-table-column>
                                <el-table-column label="关系">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.symbol" placeholder="请选择">
                                            <el-option
                                                v-for="item in manOptions"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据下限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.lower_limit" :disabled="scope.row.symbol == '1' || scope.row.symbol == '2'"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据上限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.upper_limit" :disabled="scope.row.symbol == '3' || scope.row.symbol == '4'"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="分值">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.score"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="操作">
                                    <template slot-scope="scope">
                                        <el-button
                                        type="text"
                                        size="small"
                                        v-if="scope.$index > 0"
                                        @click="manMoveClick(scope.$index)"
                                        >
                                        移除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <i class="el-icon-circle-plus-outline manAddIcon" @click="manAddClick()"></i>
                        </el-form-item>

                        <el-form-item v-if="activeName == 'second'">
                            <p>分值设置（女）</p>
                            <el-table
                                :data="womanTableData"
                                border
                                style="width: 100%">
                                <el-table-column
                                    type="index"
                                    width="50"
                                    label="区间编号">
                                </el-table-column>
                                <el-table-column label="关系">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.symbol" placeholder="请选择">
                                            <el-option
                                                v-for="item in manOptions"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据下限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.lower_limit" :disabled="scope.row.symbol == '1' || scope.row.symbol == '2'"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据上限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.upper_limit" :disabled="scope.row.symbol == '3' || scope.row.symbol == '4'"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="分值">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.score"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="操作">
                                    <template slot-scope="scope">
                                        <el-button
                                        type="text"
                                        size="small"
                                        v-if="scope.$index > 0"
                                        @click="womanMoveClick(scope.$index)"
                                        >
                                        移除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <i class="el-icon-circle-plus-outline manAddIcon" @click="womanAddClick()"></i>
                        </el-form-item>

                        <div class="form-bottom-button">
                            <el-button @click.native="save" type="primary" v-no-more-click>保存</el-button>
                            <el-button @click="remove">取消</el-button>
                        </div>
                    </el-form>
                </div>
            </el-dialog>
            <!--            查看弹窗-->
            <el-dialog :close-on-click-modal="false" :visible.sync="detailsDialog" center class="checkContent"
                       title="查看指标">
                <div class="details-wrap dialogBox">
                    <div class="detailsItem">
                        <div>
                            <span>编号</span>
                            <span>：{{form.code}}</span>
                        </div>
                        <div>
                            <span>指标名称</span>
                            <span>：{{form.cn_name}}</span>
                        </div>
                        <div>
                            <span>单位</span>
                            <span>：{{this.unitArr}}</span>
                        </div>
                        <div>
                            <span>指标说明</span>
                            <span>：{{form.norm_explain}}</span>
                        </div>
                        <div>
                            <span>顺序</span>
                            <span>：{{form.order_no}}</span>
                        </div>
                        <div>
                            <el-tabs v-model="activeName" style="width: 70%; padding-left: 0;">
                                <el-tab-pane label="范围设置" name="first"></el-tab-pane>
                                <el-tab-pane label="评分设置" name="second"></el-tab-pane>
                            </el-tabs>
                        </div>
                        <div v-if="activeName == 'first'">
                            <span>标准范围（男）</span>
                            <el-select disabled placeholder="选择关系" style="width: 35%;padding-left: 0;" v-model="form.male_symbol === 1 ? '<' :form.male_symbol=== 2? '<=' :form.male_symbol=== 3? '>' :form.male_symbol===4? '>='
                                    : form.male_symbol=== 5?'区间（3段）左开右开' :form.male_symbol=== 6? '区间（3段）左开右闭' :form.male_symbol===7? '区间（3段）左闭右开': '区间（3段）左闭右闭'">
                            </el-select>
                            <span class="standard" v-show="form.male_symbol === 1">小于（<）</span>
                            <span v-show="form.male_symbol === 1">{{form.male_upper_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 2">小于等于（<=）</span>
                            <span v-show="form.male_symbol === 2">{{form.male_upper_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 3">大于（>）</span>
                            <span v-show="form.male_symbol === 3">{{form.male_lower_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 4">大于等于（>=）</span>
                            <span v-show="form.male_symbol === 4">{{form.male_lower_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 5">区间（3段）左开右开</span>
                            <span v-show="form.male_symbol === 5">{{form.male_lower_limit}}至</span>
                            <span v-show="form.male_symbol === 5">{{form.male_upper_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 6">区间（3段）左开右闭</span>
                            <span v-show="form.male_symbol === 6">{{form.male_lower_limit}}至</span>
                            <span v-show="form.male_symbol === 6">{{form.male_upper_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 7">区间（3段）左闭右开</span>
                            <span v-show="form.male_symbol === 7">{{form.male_lower_limit}}至</span>
                            <span v-show="form.male_symbol === 7">{{form.male_upper_limit}}</span>
                            <span class="standard" v-show="form.male_symbol === 8">区间（3段）左闭右闭</span>
                            <span v-show="form.male_symbol === 8">{{form.male_lower_limit}}至</span>
                            <span v-show="form.male_symbol === 8">{{form.male_upper_limit}}</span>
                        </div>
                        <div v-if="activeName == 'first'">
                            <span>标准范围（女）</span>
                            <el-select disabled placeholder="选择关系" style="width: 35%;padding-left: 0;" v-model="form.female_symbol === 1 ? '<' :form.female_symbol=== 2? '<=' :form.female_symbol=== 3? '>' :form.female_symbol===4? '>='
                                    : form.female_symbol=== 5?'区间（3段）左开右开' :form.female_symbol=== 6? '区间（3段）左开右闭' :form.female_symbol===7? '区间（3段）左闭右开': '区间（3段）左闭右闭'">
                            </el-select>
                            <span class="standard" v-show="form.female_symbol === 1">小于（<）</span>
                            <span v-show="form.female_symbol === 1">{{form.female_upper_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 2">小于等于（<=）</span>
                            <span v-show="form.female_symbol === 2">{{form.female_upper_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 3">大于（>）</span>
                            <span v-show="form.female_symbol === 3">{{form.female_lower_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 4">大于等于（>=）</span>
                            <span v-show="form.female_symbol === 4">{{form.female_lower_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 5">区间（3段）左开右开</span>
                            <span v-show="form.female_symbol === 5">{{form.female_lower_limit}}至</span>
                            <span v-show="form.female_symbol === 5">{{form.female_upper_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 6">区间（3段）左开右闭</span>
                            <span v-show="form.female_symbol === 6">{{form.female_lower_limit}}至</span>
                            <span v-show="form.female_symbol === 6">{{form.female_upper_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 7">区间（3段）左闭右开</span>
                            <span v-show="form.female_symbol === 7">{{form.female_lower_limit}}至</span>
                            <span v-show="form.female_symbol === 7">{{form.female_upper_limit}}</span>
                            <span class="standard" v-show="form.female_symbol === 8">区间（3段）左闭右闭</span>
                            <span v-show="form.female_symbol === 8">{{form.female_lower_limit}}至</span>
                            <span v-show="form.female_symbol === 8">{{form.female_upper_limit}}</span>
                        </div>
                        <div v-if="activeName == 'second'">
                            <div style="width: 70%;padding-left: 0px;">
                                整数 
                                <el-select placeholder="选择整数" v-model="form.accuracy_num" disabled  style="width: 80%; margin-left: 0px;">
                                    <el-option
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                            v-for="item in accuracyOptions">
                                    </el-option>
                                </el-select>
                            </div>
                            <p>分值设置（男）</p>
                            <el-table
                                :data="manTableData"
                                border
                                style="width: 70%;padding-left: 0px;">
                                <el-table-column
                                    type="index"
                                    width="50"
                                    label="区间编号">
                                </el-table-column>
                                <el-table-column label="关系">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.symbol" placeholder="请选择" disabled>
                                            <el-option
                                                v-for="item in manOptions"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据下限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.lower_limit" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据上限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.upper_limit" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="分值">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.score" disabled></el-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <p>分值设置（女）</p>
                            <el-table
                                :data="womanTableData"
                                border
                                style="width: 70%;padding-left: 0px;">
                                <el-table-column
                                    type="index"
                                    width="50"
                                    label="区间编号">
                                </el-table-column>
                                <el-table-column label="关系">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.symbol" placeholder="请选择" disabled>
                                            <el-option
                                                v-for="item in manOptions"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据下限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.lower_limit" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="数据上限">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.upper_limit" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="分值">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.score" disabled></el-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="form-bottom-button">
                    <el-button @click="removeDetailsDialog()" type="primary">关闭</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    let data = {
        code: "", //编号
        cn_name: "", //指标名称
        unit_uuid: "", //单位
        norm_explain: "", //指标说明
        order_no: "", //顺序
        value_type: 1, //指标值类型
        male_symbol: "", //标准范围男
        male_lower_limit: "", //下限
        male_upper_limit: "", //上限
        female_symbol: "", //标准范围女
        female_lower_limit: "", //下限
        female_upper_limit: "", //上限
    };
    export default {
        data() {
            return {
                manOptions: [
                    {
                        name: '<',
                        value: 1
                    },
                    {
                        name: '<=',
                        value: 2
                    },
                    {
                        name: '>',
                        value: 3
                    },
                    {
                        name: '>=',
                        value: 4
                    },
                    {
                        name: '区间（3段）左开右开',
                        value: 5
                    },
                    {
                        name: '区间（3段）左开右闭',
                        value: 6
                    },
                    {
                        name: '区间（3段）左闭右开',
                        value: 7
                    },
                    {
                        name: '区间（3段）左闭右闭',
                        value: 8
                    }      
                ],
                manTableData: [],
                womanTableData: [],
                activeName: 'first',
                unitArr: [],
                detailsDialog: false, // 查看弹窗
                dialogTableVisible: false, // 新增弹窗
                dialogTableVisibleType: false, //是否是新增
                options: [],
                unitOptions: [], //存放新增单位数组
                currentPage: 1,
                tableTotal: 0,
                tableLoading: false,
                form: {
                    ...data,
                },
                tableData: [],
                classIfy: '',
                selectArr: {
                    type: "",
                    project: "",
                    jobs: "",
                    sex: "",
                    inputType: "",
                    grade: '',
                },
                value: "",
                input4: "",
                ruleForm: {
                    ...data
                },
                rules: {
                    code: this.$rules.code,
                    // code: {...this.$rules.noEmpty, message: "编号不能为空"},
                    cn_name: {...this.$rules.noEmpty, message: "指标名称不能为空"},
                    unit_uuid: {...this.$rules.noEmpty, message: "单位不能为空"},
                },
                accuracyOptions: [
                    {
                        value: 0,
                        name: '整数'
                    },
                    {
                        value: 1,
                        name: '小数点后1位'
                    },
                    {
                        value: 2,
                        name: '小数点后2位'
                    },
                    {
                        value: 3,
                        name: '小数点后3位'
                    },
                    {
                        value: 4,
                        name: '小数点后4位'
                    },
                ]
            };
        },
        created() {
            this.getData()
            this.getCate()
        },
        watch: {
            $route(){
                this.getData()
            }
        },
        mounted() {
        },
        methods: {
            removeDetailsDialog() {
                this.detailsDialog = false;
                this.manTableData = [];
                this.womanTableData = [];
                this.activeName = 'first';
            }, 
            remove() {
                this.dialogTableVisible = false;
                this.manTableData = [];
                this.womanTableData = [];
                this.activeName = 'first';
            },
            manAddClick() {
                this.manTableData.push({
                    symbol: '',
                    lower_limit: '',
                    upper_limit: '',
                    score: ''
                })
            },
            manMoveClick(index) {
                this.manTableData.splice(index, 1);
            },
            womanAddClick() {
                this.womanTableData.push({
                    symbol: '',
                    lower_limit: '',
                    upper_limit: '',
                    score: ''
                })
            },
            womanMoveClick(index) {
                this.womanTableData.splice(index, 1);
            },
            getData(e) {
                let data = {}
                if (e) {
                    data = {
                        page: '',
                        cate_uuid: this.classIfy,
                        cn_name: this.input4,
                    }
                } else {
                    data = {
                        page: this.currentPage,
                        cate_uuid: this.classIfy,
                        cn_name: this.input4,
                    }
                }
                this.tableLoading = true;
                this.$axios
                    .post(
                        "/p/norm/lists",
                        this.$qs(data)
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.tableData = res.data.data.lists;
                            this.tableTotal = res.data.data.total;
                            this.tableLoading = false;
                        }
                    });
                if (e) {
                    this.currentPage = 1
                }
            },
            //分类选中
            getClassify(e) {
                this.classIfy = e;
                this.tableLoading = true;
                this.$axios
                    .post(
                        "/p/norm/lists",
                        this.$qs({
                            page: '',
                            cate_uuid: e,
                            cn_name: this.input4,
                        })
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.tableData = res.data.data.lists;
                            this.tableTotal = res.data.data.total;
                            this.tableLoading = false;
                        }
                    });
            },
            //获取分类下拉
            getCate() {
                this.$axios
                    .get(
                        "/p/norm/getCate",
                        this.$qs({})
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            let optionArr = []
                            for (let item in res.data.data) {
                                optionArr.push({
                                    value: item,
                                    label: res.data.data[item]
                                })
                            }
                            this.options = optionArr
                        }
                    });
            },
            //改变状态
            ChangeState(row) {
                this.tableLoading = true;
                this.$axios
                    .post(
                        "/p/norm/able",
                        this.$qs({
                            uuid: row.uuid
                        })
                    )
                    .then(response => {
                        if (response.data.code === 0) {
                            this.status = row.status
                            this.$message({
                                message: response.data.message,
                                type: "success"
                            });
                            this.getData()
                            this.tableLoading = false;
                        } else {
                            this.$message({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
            },
            //标准男数据清空
            standardMan(e) {
                if (e) {
                    this.form = {
                        ...this.form,
                        male_lower_limit: '',
                        male_upper_limit: '',
                    }
                }
            },
            //标准女数据清空
            standardWoman(e) {
                if (e) {
                    this.form = {
                        ...this.form,
                        female_lower_limit: '',
                        female_upper_limit: ''
                    }
                }
            },
            ///新增里面的单位
            changeUnit(e) {
                this.$axios
                    .get(
                        "/p/unit/getValidUnits",
                        this.$qs({})
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            let unitArr = []
                            res.data.data.forEach((item) => {
                                unitArr.push({
                                    label: item.cn_name,
                                    value: item.uuid
                                })
                            })
                            this.unitOptions = unitArr
                        }
                    });
            },
            /*表格单行删除*/
            delTableData(row) {
                this.$confirm('确定要删除此行数据吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$axios.post("/p/norm/del", this.$qs({
                        uuids: row.uuid
                    })).then((res) => {
                        if (res.data.code === 0) {
                            // 解决跳页后删除本页最后一条数据查询不跳转问题
                            if (this.currentPage * 10 - 9 === this.total) {
                                this.currentPage -= 1;
                            }
                            if(this.currentPage === 0){
                                this.currentPage = 1
                            }
                            this.$utils.message();
                            this.getData();
                        } else {
                            this.$utils.message({
                                type: 'error',
                                message: res.data.message
                            })
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            },
            //新增
            addDataLog() {
                this.tableLoading = true;
                this.form = {
                    value_type: 1
                }
                this.$axios
                    .get(
                        "/p/norm/getCode",
                        this.$qs({})
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.form.code = res.data.data
                            this.tableLoading = false;
                        }
                    });
                this.$axios
                    .get(
                        "/p/unit/getValidUnits",
                        this.$qs({})
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            let unitArr = []
                            res.data.data.forEach((item) => {
                                unitArr.push({
                                    label: item.cn_name,
                                    value: item.uuid
                                })
                            })
                            this.unitOptions = unitArr
                        }
                    });
                this.dialogTableVisibleType = false;
                this.dialogTableVisible = true;
                setTimeout(() => {
                    this.$dialogHeight();
                    this.$refs['form'].clearValidate();
                }, 100)
            },
            //编辑
            editorData(row, index) {
                this.tableLoading = true;
                this.manTableData = [];
                this.womanTableData = [];
                this.$axios
                    .get(
                        "/p/unit/getValidUnits",
                        this.$qs({})
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            let unitArr = []
                            res.data.data.forEach((item) => {
                                unitArr.push({
                                    label: item.cn_name,
                                    value: item.uuid
                                })
                            })
                            this.unitOptions = unitArr
                        }
                    });
                this.$axios
                    .post(
                        "/p/norm/read",
                        this.$qs({
                            uuid: row.uuid,
                        })
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.tableLoading = false;
                            this.form = res.data.data
                            this.changeUnit(row.unit_uuid)
                            this.dialogTableVisibleType = true;
                            this.dialogTableVisible = true;
                            let setTableData =  res.data.data.norm_score;
                            setTableData.forEach((item, index) => {
                                if(item.sex == 1) {
                                    this.manTableData.push(item);
                                }
                                else {
                                    this.womanTableData.push(item);
                                }
                            })
                            let showArray = [{name:this.form.unit_name,uuid:this.form.unit_uuid}]
                            showArray.forEach((value, index, array) => {
                                console.log(value);
                                var flag = false;
                                this.unitOptions.some((v, i, a) => {
                                    if (value.uuid === v.uuid) {
                                        flag = true;
                                    }
                                });
                                if (!flag) {
                                    let unitObj = {
                                        label:value.name,
                                        value:value.uuid
                                    }
                                    this.unitOptions.push(unitObj);
                                }
                            });
                            setTimeout(() => {
                                this.$dialogHeight();
                                this.$refs['form'].clearValidate();
                            }, 100)
                        }
                    });
            },
            //新增的保存
            save() {
                let submit = () => {
                    //编辑
                    let arr = [];
                    // this.roleData.options.forEach((i,k)=>{
                    //     if(i.model){
                    //         arr.push(i.uuid)
                    //     }
                    // })

                    // norm_score:[{'sex':1,'symbol':1,'lower_limit':1,'upper_limit':10,'score':9},
                    //             {'sex':1,'symbol':1,'lower_limit':1,'upper_limit':10,'score':9}]
                    let norm_score = [];
                    this.manTableData.forEach((item, index) => {
                        item.sex = '1';
                        norm_score.push(item);
                    })
                    this.womanTableData.forEach((item, index) => {
                        item.sex = '2';
                        norm_score.push(item);
                    })
                    console.log(norm_score);
                        if (this.dialogTableVisibleType) {
                        let data = {
                            uuid: this.form.uuid, //uuid
                            code: this.form.code, //编号
                            cn_name: this.form.cn_name, //指标名称
                            unit_uuid: this.form.unit_uuid, //单位
                            norm_explain: this.form.norm_explain, //指标说明
                            order_no: this.form.order_no, //顺序
                            value_type: this.form.value_type, //指标值类型
                            male_symbol: this.form.male_symbol, //标准范围男
                            male_lower_limit: this.form.male_lower_limit, //下限
                            male_upper_limit: this.form.male_upper_limit, //上限
                            female_symbol: this.form.female_symbol, //标准范围女
                            female_lower_limit: this.form.female_lower_limit, //下限
                            female_upper_limit: this.form.female_upper_limit, //上限
                            accuracy_num: this.form.accuracy_num,
                            norm_score: JSON.stringify(norm_score)
                        }
                        for (let i in data) {
                            if (data[i] === '') {
                                delete data[i]
                            }
                        }
                        this.$axios.post("/p/norm/update", this.$qs(data)).then((res) => {
                            if (res.data.code === 0) {
                                this.getData();
                                this.dialogTableVisible = false;
                                this.$utils.message();
                            } else {
                                this.$utils.message({
                                    type: 'error',
                                    message: res.data.message
                                })
                            }
                        })
                        //新增
                    } else {
                        let data = {
                            code: this.form.code, //编号
                            cn_name: this.form.cn_name, //指标名称
                            unit_uuid: this.form.unit_uuid, //单位
                            norm_explain: this.form.norm_explain, //指标说明
                            order_no: this.form.order_no, //顺序
                            value_type: this.form.value_type, //指标值类型
                            male_symbol: this.form.male_symbol, //标准范围男
                            male_lower_limit: this.form.male_lower_limit, //下限
                            male_upper_limit: this.form.male_upper_limit, //上限
                            female_symbol: this.form.female_symbol, //标准范围女
                            female_lower_limit: this.form.female_lower_limit, //下限
                            female_upper_limit: this.form.female_upper_limit, //上限
                            accuracy_num: this.form.accuracy_num,
                            norm_score: JSON.stringify(norm_score)
                        }
                        this.$axios.post("/p/norm/add", this.$qs(data)).then((res) => {
                            if (res.data.code === 0) {
                                this.getData();
                                this.dialogTableVisible = false;
                                this.$utils.message();
                            } else {
                                this.$utils.message({
                                    type: 'error',
                                    message: res.data.message
                                })
                            }
                        })
                    }
                    this.manTableData = [];
                    this.womanTableData = [];
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        submit();
                    } else {
                        return false;
                    }
                });
            },
            /*查看表格数据*/
            examineData(row, type) {
                this.tableLoading = true;
                this.$axios
                    .get(
                        "/p/unit/getValidUnits",
                        this.$qs({})
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            let unitArr = []
                            res.data.data.forEach((item) => {
                                unitArr.push({
                                    label: item.cn_name,
                                    value: item.uuid
                                })
                            })
                            this.unitOptions = unitArr
                        }
                    });
                this.$axios
                    .post(
                        "/p/norm/read",
                        this.$qs({
                            uuid: row.uuid,
                        })
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.form = res.data.data
                            this.unitArr = []
                            let setTableData =  res.data.data.norm_score;
                            setTableData.forEach((item, index) => {
                                if(item.sex == 1) {
                                    this.manTableData.push(item);
                                }
                                else {
                                    this.womanTableData.push(item);
                                }
                            })
                            this.unitOptions.forEach((item) => {
                                if (item.value === res.data.data.unit_uuid) {
                                    this.unitArr = item.label
                                } else {
                                    this.unitArr = this.form.unit_name
                                }
                            })
                            this.tableLoading = false;
                            this.detailsDialog = true;
                        }
                    });
            },
            //  页码
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.getData();
                this.tableLoading = true;
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getData();
                this.tableLoading = true;
            },
            /*表格选中*/
            tableSelectionChange(val) {
                this.tableDataSelectArr = val;
            },
            //导出
            expor() {
                // const numberName = this.selectArr.inputType === '1' ? '&personnel_umber=' + this.input4 : this.selectArr.inputType === '2' ? "&name=" + this.input4 : '';
                window.open(this.$store.state.url + '/p/norm/export/?cate_uuid=' + this.classIfy + '&cn_name=' + this.input4)
            }
        }
    };
</script>


<style lang='scss' scoped>
.manAddIcon{
    position: absolute;
    bottom: 15px;
    left: -30px;
    font-size: 25px;
    color: #67c23a;
    &:hover{
        cursor: pointer;
    }
}
    .table-pagination {
        text-align: right;
        margin-top: 10px;

        /deep/ span {
            float: left;
        }
    }

    .child-top {
        .input-from {
            float: left;
            min-width: 850px;
            margin-bottom: 0;

            & > .el-select {
                width: 150px;
                float: left;
                margin-top: 10px;

                .el-input__inner {
                    border-radius: 0;
                    border-right: transparent;
                }

                .el-input__inner:focus {
                    border-right: 1px solid #409eff;
                }
            }

            .el-select, .el-input {
                margin-top: 10px;
            }

            .el-select:nth-child(4) {
                .el-input__inner:focus {
                    border-right: 0.052083rem solid #409eff;
                }
            }

            .el-select:nth-child(5) {
                .el-input__inner {
                    border-right: 0;
                }

                .el-input__inner:focus {
                    border-right: 0.052083rem solid #409eff;
                }
            }

            .el-select:nth-child(6) {
                width: 120px;
                margin-left: 10px;

                .el-input__inner {
                    border-radius: 0px 4px 4px 0;
                }
            }

            .el-select:nth-child(1) {
                .el-input__inner {
                    border-radius: 4px 0 0 4px;
                }
            }

            .el-input.el-input--suffix.left-child-input {
                float: left;
                width: 200px;

                .el-input__inner {
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        .operation-button {
            // float: left;
            /*margin-bottom: 10px;*/

            .el-button {
                margin-right: 0;
            }

            .el-button:nth-child(2) {
                margin-right: 10px;
            }

            .importFile {
                border-radius: 4px 0 0 4px;
            }

        }
    }

    .el-input-group__append {
        .el-button.el-button--default {
            color: #fff;
            background-color: #409eff;
            border-color: #409eff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .ModifyDoalog {
        margin-top: -20px;
        padding: 0 0 0 20px;

        & > p {
            font-size: 20px;
            color: #3B3B3B;
            text-align: center;
            margin-bottom: 20px;
        }

        .el-form {
            padding-right: 30px;
        }
    }

    .el-select {
        width: 100%;
    }

    .detailsTitle {
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
        font-size: 16px;

        & > div {
            float: left;
            margin-right: 4px;

            i {
                color: #FC940A;
                margin-right: 1px;
            }
        }

        & > div:nth-child(1) {
            margin-left: 24px;
        }
    }

    .detailsItem {
        padding: 20px 20px 0 0px;

        div {
            margin: 10px 0;
            font-size: 16px;
            padding: 0 0 0 24px;
            line-height: 28px;

            span {
                display: inline-block;
                font-weight: 400;
            }

            span:nth-child(1) {
                width: 120px;
                color: #A0A6AB;
            }

            .standard {
                margin-left: 20px;
            }
        }
    }

    /deep/ .el-dialog__body {
        overflow-y: scroll;
    }

    /*查看的弹窗*/
    .checkContent {
        /deep/ .el-dialog__body {
            overflow-y: auto;
        }
    }

    // .el-select {
    //     max-height: 0;
    // }
</style>
